* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-weight: inherit;
  font-family: inherit;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-appearance: none;
  -moz-appearance: none;
}
html {
  display: flex;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5;
  background-color: #ffffff;
  color: black;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "Graphik";
}
body {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: currentColor;
}
